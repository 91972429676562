import React, { useEffect } from "react";
import "./App.css";
import Strands from "./strands/strands.js";
import Connections from "./connections.js";
import Wordle from "./wordle.js";
import Wortel from "./wortel.js";
import Watse from "./watse.js";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const {
    location: { pathname },
  } = window;

  useEffect(() => {
    if (pathname === "/") window.location.href = "/strands";
  }, []);

  return (
    <div className='App' style={{ minWidth: "100%" }}>
      <div style={{ display: "flex", justifyContent: "center", minWidth: "100%" }}>
        {pathname.includes("strands") && <Strands />}
        {pathname.includes("connections") && <Connections />}
        {pathname.includes("wordle") && <Wordle />}
        {pathname.includes("wortel") && <Wortel />}
        {pathname.includes("watse") && <Watse />}
        {pathname.includes("final") && <FinalPage />}
      </div>
    </div>
  );
}

function FinalPage() {
  return (
    <div style={{ padding: 10 }}>
      <h1>Well done!</h1>
      <h3>You have completed all the puzzles!</h3>
      <h3>Now go looking for this:</h3>
      <h5 style={{ fontWeight: "bold", color: "green" }}>GREEN</h5>
      <h5>PORCH 🏡</h5>
      <h5>TABLE ┬─┬﻿ ノ( ゜-゜ノ)</h5>
      <p>Bye 👋</p>
    </div>
  );
}

export default App;
