import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBbhsznU5xu5xUeGMHPg9VyJQUgY8hnZXU",
  authDomain: "rentia-22.firebaseapp.com",
  projectId: "rentia-22",
  storageBucket: "rentia-22.appspot.com",
  messagingSenderId: "558336247276",
  appId: "1:558336247276:web:5be2de282c1cad4605ecc9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
