import React, { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";

function Wortel() {
  const [showWebsite, setShowWebsite] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const url = "https://mywordle.strivemath.com/?word=lcifs";

  useEffect(() => {
    setTimeout(() => !showWebsite && setShowWebsite(true), 15_000);
    setTimeout(() => !showButton && setShowButton(true), 30_000);
  }, []);

  return (
    <div style={{ paddingTop: 10 }}>
      <h3>Vir seker Wortel</h3>
      <h5>Die woord is 'n leidraad vir jou present...</h5>
      <h6>(btw dis nogsteeds Engelse woorde jammer)</h6>
      <p>Kliek "Wortel is gedoen!" knoppie wanneer jy klaar is</p>

      <p style={{ textDecoration: "underline" }}>Woorde sover: </p>
      <p>GREEN</p>

      {showButton && (
        <Button
          variant='primary'
          size="lg"
          style={{ fontSize: 12, backgroundColor: "orange", color: "black" }}
          onClick={() => {
            window.confirm("Onthou hierdie puzzle se woord, dis 'n LEIDRAAD!...") && (window.location.href = "/watse");
          }}
        >
          Wortel is gedoen!
        </Button>
      )}

      <div style={{ padding: 10 }}>
        <iframe
          height='700'
          src={url}
          title='Wortel'
          style={{ display: showWebsite ? "block" : "none", border: "10px solid orange", width: "100%" }}
          onLoad={() => setShowWebsite(true)}
        ></iframe>
      </div>
      {!showWebsite && <Spinner animation='grow' role='status' style={{ color: "orange" }}></Spinner>}
    </div>
  );
}

export default Wortel;
