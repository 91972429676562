const puzzle = {
  words: ["DEARJOHN", "LOVER", "BETTY", "THEMAN", "MEAN", "FORTHESWIFTIES"],
  grid: [
    [
      { letter: "A", word: 0 },
      { letter: "R", word: 0 },
      { letter: "J", word: 0 },
      { letter: "L", word: 1 },
      { letter: "O", word: 1 },
      { letter: "V", word: 1 },
      { letter: "E", word: 1 },
    ],
    [
      { letter: "E", word: 0 },
      { letter: "F", word: 5 },
      { letter: "O", word: 0 },
      { letter: "H", word: 0 },
      { letter: "N", word: 0 },
      { letter: "T", word: 2 },
      { letter: "R", word: 1 },
    ],
    [
      { letter: "D", word: 0 },
      { letter: "O", word: 5 },
      { letter: "B", word: 2 },
      { letter: "E", word: 2 },
      { letter: "T", word: 2 },
      { letter: "I", word: 5 },
      { letter: "Y", word: 2 },
    ],
    [
      { letter: "T", word: 3 },
      { letter: "R", word: 5 },
      { letter: "E", word: 5 },
      { letter: "S", word: 5 },
      { letter: "T", word: 5 },
      { letter: "E", word: 5 },
      { letter: "S", word: 5 },
    ],
    [
      { letter: "H", word: 3 },
      { letter: "T", word: 5 },
      { letter: "H", word: 5 },
      { letter: "W", word: 5 },
      { letter: "F", word: 5 },
      { letter: "E", word: 4 },
      { letter: "A", word: 4 },
    ],
    [
      { letter: "E", word: 3 },
      { letter: "M", word: 3 },
      { letter: "A", word: 3 },
      { letter: "N", word: 3 },
      { letter: "I", word: 5 },
      { letter: "M", word: 4 },
      { letter: "N", word: 4 },
    ],
  ],
};

export { puzzle };
